<template>
	<div class="product has-text-centered">
		<router-link
			v-if="fromPage === 'home'"
			:to="
				`/produse/produs/${formatProductTitle(product.titlu)}-${
					product.id
				}`
			"
			class="product__image is-block mb-4"
		>
			<img
				height="400"
				:src="`${databaseLink}/${product.imagine.url}`"
				:alt="product.titlu"
			/>
		</router-link>
		<router-link
			v-else
			:to="
				`${$route.path}/produse/${formatProductTitle(product.titlu)}-${
					product.id
				}`
			"
			class="product__image is-block mb-4"
		>
			<img
				height="400"
				:src="`${databaseLink}/${product.imagine.url}`"
				:alt="product.titlu"
			/>
		</router-link>
		<router-link
			v-if="fromPage === 'home'"
			:to="
				`/produse/produs/${formatProductTitle(product.titlu)}-${
					product.id
				}`
			"
			class="product__title is-block mb-4"
		>
			{{ product.titlu }}
		</router-link>
		<router-link
			v-else
			:to="
				`${$route.path}/produse/${formatProductTitle(product.titlu)}-${
					product.id
				}`
			"
			class="product__title is-block mb-4"
		>
			{{ product.titlu }}
		</router-link>
		<strong class="product__price is-block mb-4">
			{{ product.pret }} RON
		</strong>
		<router-link
			v-if="fromPage === 'home'"
			:to="
				`/produse/produs/${formatProductTitle(product.titlu)}-${
					product.id
				}`
			"
			class="btn btn--primary"
			>Detalii produs</router-link
		>
		<router-link
			v-else
			:to="
				`${$route.path}/produse/${formatProductTitle(product.titlu)}-${
					product.id
				}`
			"
			class="btn btn--primary"
			>Detalii produs</router-link
		>
	</div>
</template>

<script>
	export default {
		props: {
			product: {
				type: Object,
				require: true,
			},
			fromPage: String,
		},
		computed: {
			databaseLink() {
				return process.env.VUE_APP_STRAPI_DATABASE_URL;
			},
		},
		methods: {
			formatProductTitle(title) {
				let str = title.toLowerCase();
				let charMap = {
					ă: 'a',
					â: 'a',
					î: 'i',
					ș: 's',
					ț: 't',
				};
				let rx = /(ă|â|î|ș|ț)/g;
				if (rx.test(str)) {
					str = str.replace(rx, function(m, key) {
						return charMap[key];
					});
				}
				str = str.replace(/[^a-z\d\s-]/gi, '');
				str = str.replace(/[\s-]+/g, ' ');
				str = str.replace(/\s/g, '-');
				return str;
			},
		},
	};
</script>

<style scoped lang="scss">
	.product {
		img {
			height: 250px;
		}
		&__title {
			font-size: 1.25rem;
			text-transform: uppercase;
			font-weight: $semibold;
		}
		&__price {
			color: lighten($primary, 20%);
			font-size: 1.125rem;
			font-weight: $semibold;
		}
	}
</style>
