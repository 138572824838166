<template>
	<section class="section section__videos section--larger">
		<div class="container">
			<div class="columns is-multiline">
				<div
					class="column is-4"
					v-for="video in videos"
					:key="video.id"
				>
					<div class="video">
						<div class="video__content is-relative mb-4">
							<iframe
								:src="video.url"
								frameborder="0"
								allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
								allowfullscreen
							></iframe>
						</div>
						<h3 v-if="video.titlu" class="has-text-centered mb-1">
							{{ video.titlu }}
						</h3>
						<h4 v-if="video.subtitlu" class="has-text-centered">
							{{ video.subtitlu }}
						</h4>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	export default {
		props: ['videos'],
	};
</script>

<style lang="scss" scoped>
	.video {
		&__content {
			border-radius: $round-corners;
			padding-top: 56.25%;
			width: 100%;
		}
		iframe {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			min-width: 100%;
			height: 100%;
		}
		h3 {
			font-size: 1.25rem;
			text-transform: uppercase;
		}
		h4 {
			color: lighten($black, 20%);
			font-size: 1.125rem;
			font-weight: $medium;
		}
	}
</style>
